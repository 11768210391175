//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
export default {
  props: {
    initialValue: { default: () => ({}) },
    frequency_type: { default: 'DAILY' },
    isCustomFrequency: { default: false },
    visible: { type: Boolean, required: true, default: () => false }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      seletedMonth: null,
      frequencyType:
        this.frequency_type === 'ONCE' || !this.isCustomFrequency ? 'DAILY' : this.frequency_type
    };
  },
  computed: {
    formItemLayout: () => ({
      labelCol: { xs: { span: 24 }, sm: { span: 6 } },
      wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
      colon: false
    }),
    formItemOptions() {
      const { advance } = this.initialValue;
      const forceMomentEn = moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY', 'en');
      const initDayOfMonth = [forceMomentEn.format('D')];
      const initDayOfWeek = [forceMomentEn.isoWeekday(moment().isoWeekday()).format('dddd')];
      const initMonth = [...Array(12).keys()].map(i => forceMomentEn.month(i).format('MMMM'));
      return {
        repeat: {
          rules: [{ required: true, message: this.$t('Please set a repeat') }],
          initialValue: advance?.repeat_every ? advance?.repeat_every : 1
        },
        daysOfWeek: {
          rules: [{ required: true, message: this.$t('Please set days') }],
          initialValue: this.frequency_type === 'WEEKLY' ? advance?.days : initDayOfWeek
        },
        daysOfMonth: {
          rules: [{ required: true, message: this.$t('Please set days') }],
          initialValue: this.frequency_type === 'MONTHLY' ? advance?.days : initDayOfMonth
        },
        months: {
          rules: [{ required: true, message: this.$t('Please set month') }],
          initialValue: advance?.months ?? initMonth
        }
      };
    },
    days() {
      return [...Array(7).keys()].map(i => ({
        key: moment(moment().year(), 'YYYY', 'en')
          .isoWeekday(i + 1)
          .format('dddd'),
        value: moment()
          .isoWeekday(i + 1)
          .format('dddd')
      }));
    },
    months() {
      return [...Array(12).keys()].map(i => ({
        key: moment(moment().year(), 'YYYY', 'en')
          .month(i)
          .format('MMMM'),
        value: moment()
          .month(i)
          .format('MMMM')
      }));
    },
    daysOfMonth() {
      return Array.from(
        Array(moment(`01-01-${moment().year()}`, 'DD-MM-YYYY').daysInMonth()),
        (_, i) => i + 1
      )
        .map(item => ({ key: `${item}`, value: `${item}` }))
        .concat({ key: 'Last', value: this.$t('message.compose.Last') });
    },
    repeatTimes() {
      return [
        {
          value: 'DAILY',
          label: this.$t('message.compose.Days')
        },
        {
          value: 'WEEKLY',
          label: this.$t('message.compose.Weeks')
        },
        {
          value: 'MONTHLY',
          label: this.$t('message.compose.Months')
        }
      ];
    }
  },
  methods: {
    handleChoice() {
      const {
        form: { validateFields }
      } = this;
      validateFields((err, values) => {
        if (!err) {
          this.$emit('done', values, this.frequencyType);
        }
      });
    },
    handleSelect(type) {
      this.frequencyType = type;
    },
    handleCancel() {
      this.$emit('close', this.initialValue);
    }
  }
};
