var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 600,
        visible: _vm.visible,
        title: _vm.$t("Advanced setting schedule"),
        centered: "",
        maskClosable: false,
        destroyOnClose: "",
        closable: false
      },
      on: { ok: _vm.handleChoice, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 18 } },
            [
              _c("a-form", { attrs: { form: _vm.form } }, [
                _vm.frequencyType === "DAILY"
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-item",
                          _vm._b(
                            {
                              attrs: {
                                label: _vm.$t("message.compose.Repeat every")
                              }
                            },
                            "a-form-item",
                            _vm.formItemLayout,
                            false
                          ),
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("a-input-number", {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "repeat_every",
                                        _vm.formItemOptions.repeat
                                      ],
                                      expression:
                                        "['repeat_every', formItemOptions.repeat]"
                                    }
                                  ],
                                  staticStyle: { flex: "1" },
                                  attrs: { min: 1 }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.frequencyType === "WEEKLY"
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-item",
                          _vm._b(
                            {
                              attrs: {
                                label: _vm.$t("message.compose.Repeat every"),
                                extra: _vm.$t(
                                  "Please set repeatly lower than 10"
                                )
                              }
                            },
                            "a-form-item",
                            _vm.formItemLayout,
                            false
                          ),
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("a-input-number", {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: [
                                        "repeat_every",
                                        _vm.formItemOptions.repeat
                                      ],
                                      expression:
                                        "['repeat_every', formItemOptions.repeat]"
                                    }
                                  ],
                                  staticStyle: { flex: "1" },
                                  attrs: { min: 1, max: 10 }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "a-form-item",
                          _vm._b(
                            { attrs: { label: _vm.$t("message.compose.On") } },
                            "a-form-item",
                            _vm.formItemLayout,
                            false
                          ),
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c(
                                  "a-select",
                                  {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "daysOfWeek",
                                          _vm.formItemOptions.daysOfWeek
                                        ],
                                        expression:
                                          "['daysOfWeek', formItemOptions.daysOfWeek]"
                                      }
                                    ],
                                    staticStyle: { flex: "1" },
                                    attrs: { mode: "multiple" }
                                  },
                                  _vm._l(_vm.days, function(item) {
                                    return _c(
                                      "a-select-option",
                                      { key: item.key },
                                      [_vm._v(" " + _vm._s(item.value) + " ")]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.frequencyType === "MONTHLY"
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-form-item",
                          _vm._b(
                            {
                              attrs: { label: _vm.$t("message.compose.Months") }
                            },
                            "a-form-item",
                            _vm.formItemLayout,
                            false
                          ),
                          [
                            _c(
                              "a-select",
                              {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "months",
                                      _vm.formItemOptions.months
                                    ],
                                    expression:
                                      "['months', formItemOptions.months]"
                                  }
                                ],
                                attrs: { mode: "multiple" }
                              },
                              _vm._l(_vm.months, function(item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.key },
                                  [_vm._v(" " + _vm._s(item.value) + " ")]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-form-item",
                          _vm._b({}, "a-form-item", _vm.formItemLayout, false),
                          [
                            _c(
                              "span",
                              { attrs: { slot: "label" }, slot: "label" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("message.compose.Days")) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "a-select",
                              {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: [
                                      "daysOfMonth",
                                      _vm.formItemOptions.daysOfMonth
                                    ],
                                    expression:
                                      "['daysOfMonth', formItemOptions.daysOfMonth]"
                                  }
                                ],
                                attrs: { mode: "multiple" }
                              },
                              _vm._l(_vm.daysOfMonth, function(item) {
                                return _c(
                                  "a-select-option",
                                  { key: item.key },
                                  [_vm._v(" " + _vm._s(item.value) + " ")]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 6 } },
            [
              _c("a-select", {
                staticStyle: { width: "100%" },
                attrs: { options: _vm.repeatTimes, value: _vm.frequencyType },
                on: { select: _vm.handleSelect }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }